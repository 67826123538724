import React, { useState, Fragment } from 'react'
import { globalFileIcon } from '../../../axiosInstance';
import Chat from './chat';

export default function CustomerService({ setOpenMenu }) {

  const [openChat, setOpenChat] = useState(false);

  return (
    <Fragment >
      <p className='header'>קליק<br /> ונשמח לעזור:</p>
      <button className='option' onClick={() => setOpenChat(true)}><img  loading="lazy" src={globalFileIcon + 'chatService.svg'} alt="chatService" />בצאט</button>
      <a href="mailto::s@hamazon.co.il" onClick={() => setOpenMenu(false)} target="_blank" className='option'><img  loading="lazy" src={globalFileIcon + 'emailService.svg'} alt="emailService" />בדוא"ל </a>
      <a href="tel:+97225327277" target="_blank" className='option' onClick={() => setOpenMenu(false)}><img  loading="lazy" src={globalFileIcon + 'phoneService.svg'} alt="phoneService" />בטלפון: </a>
      <span className='tel-num'>02-5327-277</span>
      <div className='close-menu'
        onClick={() => setOpenMenu(false)}
        style={
          {
            backgroundImage: `url(${globalFileIcon + 'closePrivateArea.svg'})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundColor: 'transparent',
          }}
      >
      </div>
      {openChat ? <Chat setOpenMenu={setOpenMenu}/> : null}
    </Fragment>
  )
}
