import React, { useState, useEffect, useRef, Fragment, useMemo } from 'react';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ProductFilters from "./ProductFilters";
import ProductPageWithImg from "../productPage/ProductPageWithImg";
import ProductLocationPopUp from '../productPage/ProductLocationPopUp';
import ComparePricePopUp from '../productPage/ComparePricePopUp';
import { globalFileServer } from '../../../../axiosInstance';
import { getFilters } from '../../../server/Filter';
import { getAllPerCategoryId } from '../../../server/Advertising';
import { getSales, getItemsPerCategoryDispatch, UpdateSort, getItemsPerWord, reloedFunc } from '../../../server/Product';
import { useDispatch, useSelector } from 'react-redux';
import PopUp from '../../containers/PopUp';
import Card from '../card/Card';
import { getAllCategory } from '../../../server/Category'
import CategoryHeaderPage from '../CategoryPageHeder';
import ProductAddToShoppingList from '../productPage/ProductAddToShoppingList';
import CategoryOrder from './CategoryOrder';
import { setToggleSidebar } from "../../../server/CartsOpen";
import { useCallback } from 'react';

const CategoryPage = () => {
	const navigate = useNavigate()

	const dragItem = useRef();
	const dragOverItem = useRef();
	const { id, FatherId, subId } = useParams();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const { categoriesParent, filterList, stationId, role, refresh,
		reloed,
		items, searchString, filterAndSort } = useSelector(state => ({
			categoriesParent: state.category.categories.filter(item => item.Id === parseInt(id)),
			filterList: state.displayProduct.filterList,
			items: state.displayProduct.itemsShow,
			reloed: state.displayProduct.reloed,
			searchString: state.displayProduct.searchString,
			refresh: state.productsInCart.refresh,
			role: state.user.role,
			stationId: state.user.stationId,
			filterAndSort: state.displayProduct.filterAndSort,
		}));
	const [itemsShow, setItemsShow] = useState(items)
	const [toggleFilter, setToggleFilter] = useState(false);
	const [comparePricePopUp, setComparePricePopUp] = useState(false);
	const [selectedProd, setSelectedProd] = useState([]);
	const [productPopUp, setProductPopUp] = useState(null);
	const [productLocationPopUp, setProductLocationPopUp] = useState(null);
	const [showValid, setShowValid] = useState("0");
	const [addToShopListPopUp, setAddToShopListPopUp] = useState(null);
	const [advertising, setAdvertising] = useState([]);
	const [countPage, setCountPage] = useState(10);
	const [pagePlace, setPagePlace] = useState(0)

	const getItems = () => {
		getAllPerCategoryId(id).then(x => setAdvertising(x.data))
		dispatch(reloedFunc())
		if (id) {
			dispatch(getItemsPerCategoryDispatch(id, stationId));
		}
		else if (pathname === "/search" && searchString) {

			dispatch(getItemsPerWord(searchString, stationId))
		}
		else if (pathname === "/sales") {
			dispatch(getSales(stationId));
		}

	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [])

	useEffect(() => {
		if (!filterList.length) {
			dispatch(getFilters())
		}
		if (!categoriesParent.length) {
			dispatch(getAllCategory());
		}
		getItems();
	}, [id, pathname, searchString, refresh])

	useEffect(() => {
		console.log(items)
		const showItem = items.filter(x => x.type === "advertising" ||
			showValid === "1" ||
			(showValid === "0" && x.DisableProductId === 1) ||
			(showValid === "-1" && x.DisableProductId > 1) ||
			(showValid === "2" && x.DisableProductId === 1 && x.QuantityCanOrder))
		if (advertising.length && items.length) {
			const itemsShowCopy = [...showItem];
			advertising.sort(() => Math.random() > Math.random());
			advertising.forEach(x => itemsShowCopy.splice(
				Math.floor(Math.random() * itemsShowCopy.length),
				0,
				{ ...x, type: "advertising" }
			))
			setItemsShow(itemsShowCopy)
		}
		setItemsShow(showItem)
		setPagePlace(0)
	}, [items, showValid])



	const sortItems = () => {
		let moveBefore = true;
		// מחלקה שנרצה להעביר אחרי המחלקה של עכשיו נרצה להעביר לסוף מחלקה הנוכחית
		if (dragItem.current.CategoryOrderNumber < dragOverItem.current.CategoryOrderNumber) {
			moveBefore = false
			dragOverItem.current.Mkt = itemsShow.reverse().find(ele => ele.CategoryOrderNumber === dragItem.current.CategoryOrderNumber).Mkt
		}
		//מחלקה שנרצה להעביר לפני המחלקה של עכשיו נרצה להעביר להתחלה
		if (dragItem.current.CategoryOrderNumber > dragOverItem.current.CategoryOrderNumber) {
			dragOverItem.current.Mkt = itemsShow.find(ele => ele.CategoryOrderNumber === dragItem.current.CategoryOrderNumber).Mkt
		}
		const data = {
			Mkt: dragOverItem.current.Mkt,
			MktChange: dragItem.current.Mkt,
			isBefore: moveBefore,
		}
		dispatch(UpdateSort(data))
	}


	const handleScroll = useCallback((e) => {
		const bottom = (Math.ceil(window.innerHeight + window.scrollY) * 1.5) >= document.documentElement.scrollHeight
		if (bottom) {
			setPagePlace(pagePlace => pagePlace + 1)
		}
	}, [document])


	const find = itemsShow.find(x => x.CategoryFather1OrderNumber == id || x.CategoryId == id || x.CategoryFatherOrderNumber == id || x.FatherId == id || x.FatherFatherId == id);
	if (categoriesParent.length || pathname === "/search" || pathname === "/sales") {
		let CategoryName = "";
		let mainTitle;
		return (
			<div className="products-view"  >

				<CategoryHeaderPage id={id} FatherId={FatherId} subId={subId} />
				{productPopUp ? <ProductPageWithImg selectedProd={selectedProd} close={() => setProductPopUp(!productPopUp)} /> : null}
				{addToShopListPopUp ? <PopUp width='500px' close={() => setAddToShopListPopUp(false)}><ProductAddToShoppingList selectedProd={selectedProd} close={() => setAddToShopListPopUp(false)} /></PopUp> : null}
				{productLocationPopUp ?
					<PopUp width='900px' close={() => setProductLocationPopUp(!productLocationPopUp)}>
						<ProductLocationPopUp product={selectedProd} /> <div onClick={() => setProductLocationPopUp(!productLocationPopUp)} className="overflow"></div></PopUp>
					: null}
				{comparePricePopUp ?
					<PopUp width='900px' close={() => setComparePricePopUp(false)}>
						<ComparePricePopUp product={selectedProd} setComparePricePopUp={() => { setComparePricePopUp(false) }} />
						<div onClick={() => setComparePricePopUp(!comparePricePopUp)} className="overflow"></div>
					</PopUp>
					: null}
				{role ? <select onChange={(e) => setShowValid(e.target.value)} value={showValid}>
					<option value="1">הצג הכל</option>
					<option value="0">הצג פעילים</option>
					<option value="2">הצג כלקוח</option>

					<option value="-1">הצג לא פעיל</option>

				</select> : null}
				{pathname === "/search" ? <h1 className="search-title">חיפוש מוצרים</h1> : null}
				{pathname === "/sales" ? <h1 className="search-title">מוצרי מבצע</h1> : null}

				<div className="flex-container">
					{filterAndSort ? <div className='side' >
						<CategoryOrder />
						{toggleFilter ? <div onClick={() => setToggleFilter(!toggleFilter)} className="fake-filter"></div> : null}
						<ProductFilters toggleFilters={toggleFilter} />
					</div> : null}
					<div className="col-lg-9 category-card">
						{reloed ? <h1>טוען מוצרים...</h1>
							/**שרה להוסיף בהתחלה  את זה אחר כך אין מוצרים במלאי */
							// itemsShow.length == 0 ? 
							: find ? <>
								{itemsShow.filter((_x, i) => i < countPage * pagePlace + countPage)
									.map((element, index) => {
										let p = null
										if (mainTitle !== element.CategoryName && (CategoryName !== element.CategoryName || !index)) {
											CategoryName = element.CategoryName
											if (pathname !== "/sales" && pathname !== "/search") {
												p = <div className="category-name" ><h1>{CategoryName}</h1></div>
											}
										}
										return (

											<Fragment key={index}>
												{p}
												{element.type === "advertising" ? <div>
													<div className="img" onClick={() => navigate(element.Url)}>

														<img loading="lazy" src={`${globalFileServer}Advertising/${element.PathUrl}`} />
													</div>
													{element.type}
												</div> : <div
													className='col-lg-2'
													onDragStart={() => dragItem.current = element}
													onDragEnter={() => dragOverItem.current = element}
													onDragEnd={role ? sortItems : null}
													draggable
												>
													<Card
														element={element}
														productPopUp={() => { setProductPopUp(true); setSelectedProd(element); dispatch(setToggleSidebar(false)); }}
														addToShopListPopUp={() => { setAddToShopListPopUp(true); setSelectedProd(element); }}
														productLocationPopUp={() => { setProductLocationPopUp(true); setSelectedProd(element); dispatch(setToggleSidebar(false)); }}
														comparePricesPopUp={() => { setComparePricePopUp(true); setSelectedProd(element); dispatch(setToggleSidebar(false)); }}
													/>
												</div>}
											</Fragment>
										);
									})}</>
								: <h1>אין מוצרים במלאי</h1>}
					</div>
				</div>
			</div>
		)
	} else {
		return null;
	}
}

export default CategoryPage;