import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { getAllCategory, CategoryUpdate } from '../../../server/Category';
import { FiEdit2, FiCheckSquare, FiSquare, FiSave } from 'react-icons/fi';
import './showCategory.scss';

const ShowCategory = ({ items = null, isNested = false, level = 0 }) => {
    const categoriesState = useSelector(state => state.category.categories);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentItems, setCurrentItems] = useState(items || []);

    useEffect(() => {
        if (!categoriesState) {
            dispatch(getAllCategory());
        } else if (!items) {
            setCurrentItems(categoriesState.filter(x => x.FatherId === 0));
        }
    }, [categoriesState, items, dispatch]);

    const updateCategory = (id, title, value) =>{
        dispatch(CategoryUpdate({
            Mkt: id,
            Title: title,
            value: value
        }));
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const reorderedItems = [...currentItems];
        const [removed] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, removed);
        reorderedItems.forEach((item, index) => { 
            item.OrderNumber = index + 1; 
            updateCategory(item.Id, "OrderNumber", item.OrderNumber); 
        });
        setCurrentItems(reorderedItems);
        let items = categoriesState;
        items.sort((a, b) => { return a.OrderNumber - b.OrderNumber });
    };

    const [expandedCards, setExpandedCards] = useState({});
    const [renamingCardId, setRenamingCardId] = useState(null);
    const [renameInputValue, setRenameInputValue] = useState("");

    const toggleExpandCard = (cardId) => {
        setExpandedCards(prev => ({
            ...prev,
            [cardId]: !prev[cardId]
        }));
    };

    const handleCardClick = (item) => {
        const children = categoriesState.filter(x => x.FatherId === item.Id);

        if (children.length === 0) {
            navigate(`/products-edit/${item.Id}`);
        } else {
            toggleExpandCard(item.Id);
        }
    };

    const toggleActiveStatus = (card) => {
        const updatedCard = { ...card, Active: !card.Active };
        updateCategory(updatedCard.Id, "Active", updatedCard.Active);
        setCurrentItems(currentItems.map(item => item.Id === card.Id ? updatedCard : item));
    };

    const startRenamingCard = (card) => {
        setRenamingCardId(card.Id);
        setRenameInputValue(card.Name);
    };

    const saveRenamingCard = (card) => {
        updateCategory(card.Id, "Name", renameInputValue);
        setCurrentItems(currentItems.map(item => item.Id === card.Id ? { ...card, Name: renameInputValue } : item));
        setRenamingCardId(null);  
    };

    const getBackgroundColor = () => {
        switch (level) {
            case 1: return '#f0f9ff';
            case 2: return '#e0f7fa';
            default: return '#fff';
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={isNested ? `nested-list-${level}` : 'list'}>
                {(provided) => (
                    <div
                        className={`categories-container ${isNested ? 'nested-categories' : ''}`}
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {currentItems.map((item, index) => {
                            const children = categoriesState.filter(x => x.FatherId === item.Id);
                            return (
                                <Draggable key={item.Id.toString()} draggableId={item.Id.toString()} index={index}>
                                    {(provided) => (
                                        <div className="category-item"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}>
                                            <div className={`category-content level-${level}`} style={{
                                                backgroundColor: getBackgroundColor()
                                            }}>
                                                {renamingCardId === item.Id ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                            type="text"
                                                            value={renameInputValue}
                                                            onChange={(e) => setRenameInputValue(e.target.value)}
                                                            className="rename-input"
                                                            autoFocus
                                                        />
                                                        <button 
                                                            onClick={() => saveRenamingCard(item)} 
                                                            className="rename-button">
                                                            <FiSave size={20} />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div onClick={() => handleCardClick(item)}>{item.Name}</div>
                                                )}

                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <button 
                                                        onClick={() => startRenamingCard(item)} 
                                                        className="rename-button">
                                                        <FiEdit2 size={20} />
                                                    </button>

                                                    <button
                                                        onClick={() => toggleActiveStatus(item)} 
                                                        className="toggle-active-button">
                                                        {item.Active ? <FiCheckSquare size={20} /> : <FiSquare size={20} />}
                                                    </button>
                                                </div>
                                            </div>

                                            {expandedCards[item.Id] && children && (
                                                <div className="nested-categories">
                                                    <ShowCategory
                                                        items={children}
                                                        isNested={true}
                                                        level={level + 1} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default ShowCategory;