import React, { useEffect } from 'react';
import EditorForTitle from "../tools/EditorForTitle";
import { globalFileServer,globalFileIcon } from '../../../axiosInstance'
import { editParameter, getFilters, ParameterDelete, addFilter, editFilter } from '../../server/Filter'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'sweetalert2';

const Filters = () => {

	const dispatch = useDispatch();
	const filterList = useSelector(state => state.displayProduct.filterList)



	useEffect(() => {
		dispatch(getFilters())
	}, [])

	const updateFilters = (data) => {
		let val = {
			Id: data.itemId,
			TableName: data.elementTable.TableName,
			Name: data.value
		};
		dispatch(editParameter(val))


	}
	const deleteItem = (id, TableName, ParameterName) => {
		SweetAlert.fire({
			title: 'האם אתה בטוח?',
			text: 'האם ברצונך למחוק פריט זה? לא תוכל לשחזר זאת!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#22b09a',
			cancelButtonColor: '#d80028',
			confirmButtonText: 'מחק',
			cancelButtonText: 'בטל'

		}).then((res) => {
			if (res.value) {
				const DelObj = {
					Id: id,
					TableName: TableName,
					ParameterName: ParameterName
				}
				dispatch(ParameterDelete(DelObj))

			}
		})
	}

	return (
		<div className="filters">
				<h1 className='title-role'>סינון</h1>
			<div className="flex-container filter-container">
				{filterList.map(element => {
					return (
						<div className="col-lg-4" key={element.Id}>
							<EditorForTitle
								element={element}
								editItems={data => dispatch(editFilter(data))}
								updateItems={data => dispatch(editFilter(data))}
								paramName={'Name'}
								dist="filter_types"
							/>
							<ul>
								{element.Items.map((e, i) => {
									return (
										<li key={i}>
											<div className="sub-title">
												<div className="arrow">
													<img  loading="lazy" src={globalFileServer + "icons/back.svg"} alt="" />
												</div>
												<EditorForTitle
													elementTable={element}
													element={e}
													editItems={(data) => dispatch(editFilter(data))}
													updateItems={updateFilters}
													paramName={'Name'}
													dist="filters"
												/>

												<div onClick={() => deleteItem(e.Id, element.TableName, element.ParameterName)} className="delete-item">
													<div className="img">
														<img  loading="lazy" src={globalFileIcon + 'trash.svg'} alt="" />
													</div>
												</div>
											</div>
										</li>
									);
								}
								)}
							</ul>
							<div className="add-item">
								<button className="btn" onClick={() => dispatch(addFilter(element.Id))} >
									<img  loading="lazy" src={globalFileIcon + 'plus.svg'} alt="" />
									<span>הוסף פילטר</span>
								</button>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	)
}

export default Filters;