
import axios from 'axios';
import { url } from '../../axiosInstance';
import * as actionType from '../store/action'
import { setPreload } from '../server/CartsOpen';

export const getAllCategory = () => {
    return dispatch => {
        axios.get(`${url}Category`, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then(x => {
                if (!Array.isArray(x.data)) {
                    x.data = []
                }
                dispatch({ type: actionType.SET_ALL_CATEGORY, payload: x.data })
            })
    }
}

export const CategoryAdd = (data) => {
    return dispatch => {
        dispatch(setPreload(true));
        axios.post(`${url}Category/add`, data, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        }).then(x => dispatch({ type: actionType.ADD_CATEGORY, payload: x.data }))
            .finally(() => dispatch(setPreload(false)));

    }
}



export const CategoryUpdate = (data) => {
    return dispatch => {
        // dispatch(setPreload(true));
        axios.post(`${url}Category`, data, {
            headers: {
                'Authorization': `bearer ${localStorage.token}`,
                "Access-Control-Allow-Origin": "*"
            },
        }).then(x => {
            dispatch({
                type: actionType.EDIT_CATEGORY, payload: {
                    id: data.Mkt,
                    paramName: data.Title,
                    value: data.value
                }
            })
        })
            .finally(() => dispatch(setPreload(false)));
    }
}

export const CategorySetImg = (data) => dispatch => dispatch({ type: actionType.EDIT_CATEGORY, payload: data })