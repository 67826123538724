import SweetAlert from 'sweetalert2';

const showAlert = ({ msgTitle, msgText, msgType, msgTimer }) => {
    SweetAlert.fire({
        icon: msgType ? msgType : 'error',
        title: msgTitle ? msgTitle : 'שגיאה בחיבור לרשת',
        text: msgText !== null ? msgText : 'בבקשה נסה שוב מאוחר יותר',
        timer: msgTimer ? msgTimer : 2000,
        showConfirmButton: false,
    })
}
export default showAlert;

export const showAlertHTML = (msgHtml) => {
    SweetAlert.fire({
        html: msgHtml,
        showConfirmButton: false,
        showCloseButton: true
    })
}
