import React, { Fragment, useState } from 'react';
import { setUserEntry, customerRegister, PutCustomer, loginToken } from '../../server/Customer'
import { useDispatch, useSelector } from 'react-redux';
import { globalFileServer } from '../../../axiosInstance'
import { useForm } from 'react-hook-form';
import Input from "../containers/InputForm";
import { validPassword, validPhoneNumber } from '../../ValidFunction';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { setPreload } from '../../server/CartsOpen'
import showAlert from '../../server/ShowAlert';

const UserEntry = ({ parentName }) => {
    const schema = yup.object({
        Email: yup.string().email("כתובת מייל"),
        Name: yup.string().required("שם חובה"),
        LastName: yup.string().required("שם חובה"),
        CustomerWebId: yup.string(),
        BirthDate: yup.date(),
        Phone2: yup.string().matches(validPhoneNumber, 'מספר טלפון לא תקין'),
        Phone: yup.string("מספרים בלבד").required('חובה להכניס מספר טלפון').matches(validPhoneNumber, 'מספר טלפון לא תקין'),
        password: parentName === 'signIn' ? yup.string().required('חובה להכניס סיסמה').matches(validPassword, "סיסמה חייבת להכיל 8 אותיות") : null,
        confirmPass: parentName === 'signIn' ? yup.string().required("").oneOf([yup.ref('password'), null], 'סיסמה לא מתאימה') : null,
        InvoicingName: parentName === 'privateArea' || parentName === 'endShopping' ? yup.string() : null,
    }).required();

    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const [invoicing, setInvoicing] = useState("MyName");
    const { register, handleSubmit,  formState: { errors } } = useForm({
        defaultValues: { ...user },
        resolver: yupResolver(schema)
    });
    const preload = useSelector(state => state.openCart.preload)

    const send = (data) => {
        if (!preload) {
            dispatch(setPreload(true));
            if (parentName === 'signIn') {
                data.StationId = 1;
                data.Active = 1;
                if (data.Verifiad && data.VerifiadGetSms) {
                    dispatch(customerRegister(data))
                }
                else {
                    showAlert({ msgTitle: "אשר את התקנון וקבלת עדכונים" })
                    dispatch(setPreload(false));
                }
            }
            else {
                dispatch(PutCustomer(data));
            }

        }
    }

    return (
        <Fragment>
            <form className="register-form" onSubmit={handleSubmit(send)}>
                {parentName === 'signIn' ? <span className='note'>שדות חובה מסומנים ב*</span> : null}
                <div className='details-form'>
                    <div className='column-form'>
                        <Input
                            type="text"
                            register={register}
                            label={parentName == 'endShopping' ? "שם פרטי" : "שם פרטי*"}
                            disabled={parentName === 'endShopping' ? true : false}
                            errors={errors}
                            name="Name" />
                        <Input
                            type="number"
                            register={register}
                            label={parentName === 'signIn' ? "טלפון*" : "טלפון"}
                            disabled={parentName === 'signIn' ? false : true}
                            
                            errors={errors}
                            name="Phone" />
                        {parentName === 'signIn' ?
                            <Input
                                type="password"
                                register={register}
                                errors={errors}
                                label="סיסמה*"
                                name="password" /> : null}
                        <Input
                            type="email"
                            register={register}
                            label={parentName === 'signIn' ? "דואר אלקטרוני*" : "דואר אלקטרוני"}
                            disabled={parentName === 'signIn' ? false : true}
                            
                            errors={errors}
                            name="Email" />
                        {parentName === 'signIn' ?
                            <div className='checkbox-style'><Input type="checkbox" register={register} name="Verifiad" label={<span className='verify'> הנני מאשר/ת כי קראתי את <a target="_blank" href={globalFileServer + 'policy_form.pdf'}>תקנון </a>
                                אתר ׳המזון׳ ואת מדיניות הפרטיות, וברצוני להירשם בכפוף לתנאיהם.  </span>} /> </div> : null}
                        {parentName === 'signIn' ?
                            <div className='checkbox-style'><Input type="checkbox" register={register} name="VerifiadGetSms" label={<span className='verify'>הנני מאשר/ת לקבל הטבות, ועדכונים בדוא״ל וב-SMS
                                (ניתן להפסיק את קבלת העדכונים בכל עת).</span>} />
                            </div> : null}
                        {parentName == 'privateArea' || parentName == 'endShopping' ? <div className='invoicing-name'>
                            <p>חשבונית</p>
                            <div className='invoicing-radio-btn'>
                                <input type="radio" onChange={(e) => setInvoicing(e.target.value)} id="MyName" value="MyName" name="invoicing" checked={invoicing === "MyName"} />

                                <label className="MyName">על שמי</label>
                                <input type="radio" onChange={(e) => setInvoicing(e.target.value)} id="OtherName" value="OtherName" name="invoicing" checked={invoicing === "OtherName"} />

                                <label className="OtherName"> שם אחר</label>
                            </div>
                            {invoicing === "OtherName" ? <Input type="text" register={register} label="חשבונית על שם " errors={errors} name="InvoicingName" /> : null}
                        </div> : null}
                    </div>
                    <div className='column-form'>
                        <Input
                            type="text"
                            register={register}
                            label={parentName == 'endShopping' ? "שם משפחה" : "שם משפחה*"}
                            disabled={parentName === 'endShopping' ? true : false}
                            errors={errors}
                            name="LastName" />
                        <Input
                            type="number"
                            register={register}
                            label="טלפון נוסף"
                            errors={errors}
                            disabled={parentName === 'endShopping' ? true : false}
                            name="Phone2" />
                        {parentName === 'signIn' ?
                            <Input
                                type="password"
                                register={register}
                                errors={errors}
                                label="אימות סיסמה*"
                                name="confirmPass" />
                            : null}
                        <Input
                            type="date"

                            register={register}
                            label={parentName == 'endShopping' ? "תאריך לידה" : "תאריך לידה*"}
                            errors={errors}
                            name="BirthDate"
                            disabled={parentName === 'endShopping' ? true : false}
                        // value={parentName == 'endShopping' ? TimeYearMonthDate(getValues('BirthDate')) : getValues('BirthDate')}
                        />
                        {parentName === 'endShopping' ? null : <button className='register-btn' type='submit' >{parentName === 'signIn' ? `הרשמה >` : `עדכון`}</button>}
                        {parentName === 'signIn' ? <button className="cancel" onClick={() => dispatch(setUserEntry(false))}>כניסה ללא הרשמה&gt;</button> : null}

                    </div>
                </div>
            </form>

        </Fragment>

    )
}

export default UserEntry