import React from 'react';
import PersonalInformation from './PersonalInformation';
import { globalFileServer } from '../../../axiosInstance';
import AddressShipping from '../addressShipping/AddressShipping';
import { useSelector } from 'react-redux';
import DeliveryTime from './DeliveryTime';

const PrivateArea = ({ parent,setSelectedAddres }) => {
    const user = useSelector(state => state.user.user);

    return (
        <div>
            <div className="private-area">
                {parent === 'cart' ?
                    <h1 className='head-page'>סיום קניה ותשלום</h1>
                    : <div className='head'>
                        <img  loading="lazy" src={globalFileServer + 'iconcss/privateArea.svg'} alt="close" />
                        <h1 className='head-page'>האזור האישי של {user.Name} {user.LastName}</h1>
                    </div>}
                <PersonalInformation parent={parent} />
                <AddressShipping setSelectedAddres={setSelectedAddres} parent='private-area'/>
                {parent === 'cart' ? <DeliveryTime /> : null}

            </div>

        </div>
    )
}

export default PrivateArea