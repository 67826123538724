import React, { useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector, } from 'react-redux';
import { getAllCategory } from '../../../server/Category'


const CategoryOrder = () => {
    const dispatch = useDispatch();
    const categoriesState = useSelector(state => state.category.categories);
    useEffect(() => {
        if (!categoriesState.length) {
            dispatch(getAllCategory());
        }
    }, [])

    const { id, FatherId, subId } = useParams();
    let categories = [];
    let subCategories = []
    let categoryTitle = ""
    if (FatherId) {
        categoryTitle = categoriesState.filter(item => item.Id === parseInt(FatherId))[0].Name
        categories = categoriesState.filter(item => item.FatherId === parseInt(FatherId))
        subCategories = categoriesState.filter(item => item.FatherId === parseInt(subId));
    }
    else
        if (subId) {
            categoryTitle = categoriesState.filter(item => item.Id === parseInt(subId))[0].Name
            categories = categoriesState.filter(item => item.FatherId === parseInt(subId));
            subCategories = categoriesState.filter(item => item.FatherId === parseInt(id))

        }
        else {
            if(id){
            categoryTitle = categoriesState.filter(item => item.Id === parseInt(id))[0].Name
            categories = categoriesState.filter(item => item.FatherId === parseInt(id));
            }
        }

    return (
        <div className="category-nav">
            <p className='category-title'>{categoryTitle}</p>
            <ul>
                <li>
                    {categories ? categories.map((element) => {
                        return (
                            <div className="category-name" key={element.Id}>
                                <NavLink to={'/category/' + element.FatherId + '/' + element.Id} className={element.Id == id ? "name check" : "name"}>
                                    <span >{element.Name}</span>
                                </NavLink>
                                {subCategories && subCategories.map((elementSubCategory) => {
                                    if (elementSubCategory.FatherId == element.Id) {
                                        return (
                                            <div className={elementSubCategory.Id == id ? "sub-category-name checked" : "sub-category-name"} key={elementSubCategory.Id}>
                                                <NavLink to={'/category/' + element.FatherId + '/' + elementSubCategory.FatherId + '/' + elementSubCategory.Id}>
                                                    <span>{elementSubCategory.Name}</span>
                                                </NavLink>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        )
                    }
                    )
                        : null}
                </li>
            </ul>
        </div>

    )
}
export default CategoryOrder

