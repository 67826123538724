import React, { useState } from 'react';
import { sendPassword, loginToken, setUserEntry } from '../../server/Customer'
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Input from "../containers/InputForm";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


const schema = yup.object({
    userName: yup.string().required("שדה חובה"),
    password: yup.string().required('חובה להכניס סיסמה')
}).required();

const UserLogin = () => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [forgotPass, setForgotPass] = useState(false)

    const sendPass = () => {
        dispatch(sendPassword(email));
    }

    const signIn = (data) => {
        data["grant_type"] = "password"
        dispatch(loginToken(data, true));
    }

    return (
        !forgotPass ? <form className="login-form" onSubmit={handleSubmit(signIn)}>

            <Input
                errors={errors}
                label="טלפון / דואר אלקטרוני"
                type="text"
                name="userName"
                register={register} />
            <Input
                errors={errors}
                label="סיסמה"
                name="password"
                type="password"
                register={register}
            />

            <button className='login-btn' type='submit' >כניסה &gt;</button>
            <span onClick={() => setForgotPass(true)} className="forgot-pass">שכחת את הסיסמה?</span>
            <button className="cancel" onClick={() => dispatch(setUserEntry(false))}>כניסה ללא הרשמה&gt;</button>
        </form>
            :
            <div className="forgot-pass-wrap">
                    <p> אנא הקלידו את כתובת המייל של החשבון שלך לאיפוס סיסמא</p>
                    <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="אימייל" value={email} />
                    <button className='send-btn' onClick={sendPass}>שלח</button>
            </div>
    )
}

export default UserLogin