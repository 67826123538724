import { globalFileServer } from '../../../../axiosInstance';
import React, { Fragment, useState } from 'react';
import { CategoryAdd } from '../../../server/Category'
import { useDispatch } from 'react-redux';
import ShowCategory from './showCategory';

const CategoriesEdit = () => {

	const dispatch = useDispatch();

	const [search, setSearch] = useState("")
	const [addCategory, setAddCategory] = useState(false);
	const [NewCategory, setNewCategory] = useState("");
	const [newFatherCategory, setNewFatherCategory] = useState("");

	const searchFun = (e) => {
		let search = e.target.value;
		setSearch(search);
	}
	const clearSearch = () => {
		setSearch("");
	}
	const AddCategory = () => {
		const data = {
			FatherId: newFatherCategory ? newFatherCategory : 0,
			Name: NewCategory
		}
		dispatch(CategoryAdd(data));
		setNewCategory('');
		setAddCategory(false)

	}



	return (
		<div className="category-edit">
			<h1 className='title-role'>ניהול מחלקות</h1>
			<div className="items-container">
				<div className="flex-container">
					{addCategory ?
						<Fragment>
							<div className="add-category">
								<label >הכנס שם מחלקה</label>
								<input value={NewCategory} onChange={(event) => setNewCategory(event.target.value)} />
								<label >הכנס מזהה אב מחלקה</label>
								<input value={newFatherCategory} onChange={(event) => setNewFatherCategory(event.target.value)} />
								<button className='btn' onClick={AddCategory}>	שמור</button>
							</div>
						</Fragment> :
						<button className="btn" onClick={() => setAddCategory(true)}>הוסף מחלקה</button>}
					<div className="search">
						<input
							onChange={searchFun}
							value={search}
							type="text"
							placeholder="חיפוש..."
						/>
						{search ?
							<img  loading="lazy" className="close" onClick={clearSearch} src={globalFileServer + "icons/close.svg"} alt="close" />
							:
							<img  loading="lazy" src={globalFileServer + "icons/search.svg"} alt="search" />
						}
					</div>
				</div>
			</div>
			<ShowCategory />

		</div>
	)

}


export default CategoriesEdit;