import React, { Fragment, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import Calendar from 'react-calendar';
import moment from 'moment'
import { globalFileServer, globalFileIcon } from '../../../../axiosInstance';
import { GetProductsToOrder } from '../../../server/ProductToOrder';
import { orderGet } from '../../../server/Order'
import { setPreload } from '../../../server/CartsOpen';
import { useDispatch } from 'react-redux';
import { showAlertHTML } from '../../../server/ShowAlert'
import PopUp from '../../containers/PopUp';

const History = () => {
	const dispatch = useDispatch();

	const [date, setDate] = useState(new Date());
	const [toDate, setToDate] = useState(new Date());
	const [items, setItems] = useState([]);
	const [tempItems, setTempItems] = useState([])
	const [search, setSearch] = useState('');
	const [showToCalendar, setShowToCalendar] = useState(null);
	const [showCalendar, setShowCalendar] = useState(null)

	const [activeOrder, setActiveOrder] = useState(false)
	const [openInfo, setOpenInfo] = useState(false)
	const [dataElement, setDataElement] = useState({})

	useEffect(() => {
		let currentDate = date.toLocaleDateString('ru-RU').split('.').join('/');
		getItems(currentDate, currentDate);
		setTimeout(() => window.scrollTo(0, 0), 100);
	}, [])

	const setSearchFun = (e) => {
		let search = e.target.value.toLowerCase();
		let tempItems = items.filter(x => x.CustomerName.toLowerCase().includes(search));
		setTempItems(tempItems);
		setSearch(search)
	}
	const clearSearch = () => {
		setTempItems(items);
		setSearch("")
	}
	const calendarChange = (date, action) => {

		if (action === "from") {
			setDate(date);
			setShowCalendar(false);
			getItems(date.toLocaleDateString('ru-RU').split('.').join('/'), toDate.toLocaleDateString('he-IL').split('.').join('/'));

		} else {
			setToDate(date);
			setShowToCalendar(false);
			getItems(toDate.toLocaleDateString('he-IL').split('.').join('/'), date.toLocaleDateString('ru-RU').split('.').join('/'));

		}
	}

	const selectDate = (action) => {
		if (action === "from") {
			setShowCalendar(!showCalendar);

		} else {
			setShowToCalendar(!showToCalendar);
		}
	}

	const showInfo = (element) => {
		setOpenInfo(true)
		setDataElement(element)
	}

	const setActiveOrderFun = (id) => {
		if (activeOrder !== id) {
			let findInd = items.findIndex(x => x.OrderId === id);
			if (!items[findInd].Products) {
				GetProductsToOrder(id)
					.then(x => {
						const itemsEdit = [...items];
						itemsEdit[findInd].Products = x.data
						setItems(itemsEdit);
						setActiveOrder(id)
					})
					.catch(() => console.log("error"));
			}
			setActiveOrder(id);
		}
		else {
			setActiveOrder(false);
		}
	}

	const getItems = (date, toDate) => {

		date = date.split("/");
		date = date[2] + "-" + date[1] + "-" + date[0];
		toDate = toDate.split("/");
		toDate = toDate[2] + "-" + toDate[1] + "-" + toDate[0];

		let val = {
			from: date,
			to: toDate,
		};
		dispatch(setPreload(true))
		orderGet(val)
			.then(x => {
				setItems(x.data);
				setTempItems(x.data);
				setSearch('')
			})
			.catch(() => console.log("error"))
			.finally(() => dispatch(setPreload(false)));
	}

	return (
		<div className="history">
			<h1 className='title-role'>היסטוריית הזמנות</h1>
			<div className="items-container">
				<div className="heading">
					<div className="open-calendar">
						<button className="toggle" onClick={() => selectDate("from")}>
							<img loading="lazy" src={globalFileIcon + 'calendar.svg'} alt="" />
							<span>{date.toLocaleDateString('he-IL').split('.').join('/')}</span>
						</button>
						<Calendar
							onChange={(date) => calendarChange(date, "from")}
							value={date}
							calendarType="Hebrew"
							locale="he-IL"
							className={showCalendar ? 'active' : null}
						/>
					</div>
					<div className="open-calendar">
						<button className="toggle" onClick={() => selectDate("to")}>
							<img loading="lazy" src={globalFileIcon + 'calendar.svg'} alt="" />
							<span>{toDate.toLocaleDateString('he-IL').split('.').join('/')}</span>
						</button>
						<Calendar
							onChange={(date) => calendarChange(date, "to")}
							value={toDate}
							calendarType="Hebrew"
							locale="he-IL"
							className={showToCalendar ? 'active' : null}
						/>
					</div>
					<div className="search">
						<input
							onChange={setSearchFun}
							value={search}
							type="text"
							placeholder="שם לקוח..."
						/>
						{search ?
							<img loading="lazy" className="close" onClick={clearSearch} src={globalFileServer + "icons/close.svg"} alt="" />
							:
							<img loading="lazy" src={globalFileServer + "icons/search.svg"} alt="" />
						}
					</div>
				</div>
			</div>
			{!tempItems.length ? <h1 className="no-products">הזמנות אינן קיימות</h1> :
				<table>
					<tbody>
						<tr>
							<th></th>
							<th>#</th>
							<th>שם לקוח </th>
							<th>זמן ותאריך </th>
							<th>מס מוצרים</th>
							<th>סכום הזמנה</th>
							<th>סכום לוקט</th>
							<th>פרטי לקוח</th>
							<th>סטאטוס</th>
							<th>חיוב</th>
						</tr>
						{tempItems.map(element => {
							return (
								<Fragment key={element.Id}>
									<tr key={element.Id} className={activeOrder === element.OrderId ? "item active" : "item"}>
										<td >
											<img loading="lazy" src={globalFileIcon + 'down.svg'} alt="" onClick={() => setActiveOrderFun(element.OrderId)} />
										</td>
										<td>
											<p>{element.OrderWebId}</p>
										</td>
										<td>
											<p>{element.CustomerName}</p>
										</td>
										<td>
											<p>{moment(element.ExpectedDate).format("dd/MM/yy HH:mm")}</p>
										</td>
										<td>
											<p>{element.NumOfProducts}</p>
										</td>
										<td>
											<p className="price">{element.PaymentOrg} ₪</p>
										</td>
										<td>
											<p className="price">{element.Payment} ₪</p>
										</td>
										<td>
											<img loading="lazy" src={globalFileIcon + 'info.svg'} alt="" onClick={() => showInfo(element)} />
										</td>
										<td>
											<p className="Active">{element.StatusName}</p>
										</td>
										<td>
											<p className="Active">{element.PaymentStatus}</p>
										</td>
									</tr>
									{activeOrder != false && element.Products && element.Products.length ?
										<>
											<tr className='product-list'>
												<td></td>
												<td></td>
												<td>
													<p>שם מוצר</p>
												</td>
												<td>
													<p>מק"ט</p>
												</td>
												<td>
													<p>כמות</p>
												</td>
												<td>
													<p>מחיר</p>
												</td>
												<td>
													<p>הערות</p>
												</td>
											</tr>
											{element.Products.map((el) => {
												return (
													<tr key={el.Id} className='product'>
														<td></td>
														<td></td>
														<td>
															<p>{el.ProductFullDescription}</p>
														</td>
														<td>
															<p>{el.ProductMkt}</p>
														</td>
														<td>
															<p>{el.Quantity}</p>
														</td>
														<td>
															<p className="price">{el.Price} ₪</p>
														</td>
														<td>
															<p>{el.Comment}</p>
														</td>
													</tr>
												)
											})}
										</>
										: null}
								</Fragment>
							)
						}
						)}
					</tbody>
				</table>
			}
			{openInfo ?
				<PopUp width='600px' close={() => setOpenInfo(false)}>
					<div className='comment-popup'>
						<p>שם: {dataElement.CustomerName}</p>
						<p>מייל: {dataElement.Email}</p>
						<p>טלפון: {dataElement.Phone}</p>
						<p>כתובת משלוח: {dataElement.FullAddress}</p>
						{dataElement.Comment ? <p>הערה: {dataElement.Comment}</p> : null}
					</div>
				</PopUp> : null}
		</div>
	)
}

export default History;
