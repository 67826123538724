import React from 'react';
import { NavLink } from "react-router-dom";
import UserSignIn from '../header/UserSighin';
import DetailsWindow from '../containers/DetailsWindow';

const PersonalInformation = ({ parent }) => {

    return (
        <div>
            <section id='info'></section>
            {parent === 'cart' ?
                <DetailsWindow title='פרטי לקוח'>
                    <NavLink className='btn-edit-details' to="/private-area/#info">שינוי פרטים &gt; </NavLink>
                    <UserSignIn parentName='endShopping' />
                </DetailsWindow> :
                <DetailsWindow title='פרטי לקוח'>
                    <UserSignIn parentName='privateArea' />
                </DetailsWindow>
            }
        </div>
    )

}
export default PersonalInformation